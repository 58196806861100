import api from '@/api/';
import router from '../../router'
import axios from 'axios';
export default {
  namespaced: true,
  state() {
    return {
      simInfo: {},
      curr_pay: undefined,
      curr_win: undefined,
      currCert: {},
      promocode: {}, 
      appLoad: false, 
      lessons: null, 
      promo_error: null
    }
  },
  getters: {
    getSimInfo: stateFreezed => stateFreezed.simInfo,
    getCurrPay: stateFreezed => stateFreezed.curr_pay,
    getCurrWin: stateFreezed => stateFreezed.curr_win,
    getPromocode: stateFreezed => stateFreezed.promocode,
    getCurrCert: stateFreezed => stateFreezed.currCert,
    getLessons: stateFreezed => stateFreezed.lessons, 
    getError: stateFreezed => stateFreezed.promo_error

  },
  actions: {
    async SEND_NOTIFICATION(context, value) {
      
        const data = new FormData();
        data.append("token", "91dec70751837d823bc5563b62bb31f7");
        if(value.type != 'payment_start' && value.type != 'open')
          data.append("simulator", context.state.simInfo.id);
        data.append("user_id", context.rootState.user.meInfo.id);
        data.append("user_email", context.rootState.user.meInfo.email);
        
        for (const [key, item] of Object.entries(value)) {
          data.append(key, item);
        }
        
        axios.post('https://api.tommy.com.ua/sync/postback/coursesplatform/', data)
      
    },
    async FETCH({ commit }) {
      await api.get('simulators/details.json')
      .then(response => {
        commit('SET_SIM_INFO', response.data)
      })
      .catch(function (error) {
        if (error.response.status == 500) {
          commit("user/SET_LOGOUT", null, { root: true });
          router.push({ path: '/login' })
        }
      })
    },
    async LESSONS_FETCH({ commit }) {
      await api.get(`lessons.json`)
      .then(response => {
        commit('SET_SIM_LESSONS', response.data)
      })
      .catch(function (error) {
        if (error.response.status == 500) {
          commit("user/SET_LOGOUT", null, { root: true });
          router.push({ path: '/login' })
        }
      })
    },
    async PAY(context) {
      const windowPay = window.open("about:blank", "", "height=600,width=600")
      windowPay.document.write(this.$t('redirect_to_payment'))
      const data = new FormData();
      if (context.getters.getPromocode.promo_code) {
        data.append("promo_code", context.getters.getPromocode.promo_code)
      }

      const response = await api.post('payments/pay/', data)

      if (response && response.status === 200) {
        windowPay.location.replace(response.data.confirmation_url)
        windowPay.focus()

        let timer = setInterval(() => {
          api.get(`payments/${response.data.id}.json`)
          .then(result => {
            if (result.data.status === 2) {
              windowPay.close()
              context.dispatch('user/FETCH', null, {root:true})
              clearInterval(timer)
            }
          })
          .catch((error) => {
            console.log(`error`, error)
            windowPay.close()
            clearInterval(timer)
          })
        }, 10000)
      }
    },
    async ACTIVATE_PROMOCODE(context, window) {
      const data = new FormData()
      data.append('promo_code', window.promocode)
      
      await api.post(`payments/activate/`, data)
      .then(response => {
        if (response.data.success) {
          context.state.promo_error = true
          context.commit('SET_CURR_PROMOCODE', response.data)
          
        } else {
          context.state.promo_error = false
         
        }
      })
      .catch(error => {
        console.log(error.response.status)
        if (error.response.status == 404) {
          context.state.promo_error = false
        }
      })
    },
    async FETCH_CERTS(context) {
      await api.get('api/my_certificates/')
      .then(response => {
        if (response.data.length === 1) {
          context.commit("SET_CURR_CERT", response.data[0]);
        }
      })
      .catch(error => {
        console.log(`error`, error)
      })
    },
    async SET_CERT_IMAGE(context, cert) {
      const data = new FormData()
      data.append("image", cert.img)

      await api.post(`api/set_cert_image/${cert.slug}/`, data)
      .then(response => {
        return response
      })
      .catch(error => {
        console.log(`error`, error)
      });
    }
  },
  mutations: {
    SET_SIM_INFO(state, response) {
      state.simInfo = response
    },
    SET_SIM_LESSONS(state, response) {
      state.lessons = response
    },
    SET_CURR_CERT(state, response) {
      state.currCert = response
    },
    SET_CURR_PROMOCODE(state, response) {
      state.promocode = response;
    }, 
    SET_ERROR_PROMOCODE(state, response) {
      state.promo_error = response;
    }
  }
}
