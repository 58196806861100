<template>
  <div id="SideBar" class="sidebar" v-if="user && user.sim_info">
    <div
      class="sidebar__container p-3 pt-md-5 pb-4 pl-md-4 pr-md-2"
      style="position: relative"
    >
      <img
        src="@/assets/img/close.png"
        style="width: 40px; position: absolute; right: 20px; top: 10px"
        alt=""
        class="cursor-pointer"
        @click="$emit('close')"
      />
      <img
        src="@/assets/img/logo.svg"
        alt=""
        style="position: absolute; height: 16px; top: 24px; left: 20px"
      />
      <PagePreviews
        v-if="
          (routeName === 'Home' || routeName === 'HomeID') &&
          (user.sim_info.onboarding_complete || simInfo.onboarding_skip)
        "
        class="mt-5"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PagePreviews from "@/components/sidebar/PagePreviews";

export default {
  components: {
    PagePreviews,
  },
  methods: {
    async goHome() {
      window.location.href = "/home";
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getMeInfo",
      simInfo: "simulator/getSimInfo",
    }),
    routeName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/vars.scss";

.sidebar {
  background: #fff;
  // width: calc(1 / 6 * 100vw);
  min-height: 100vh;
  height: 100%;
  // padding: calc(1 / 6 * 15vw) calc(1 / 6 * 12vw) calc(1 / 6 * 12vw) calc(1 / 6 * 12vw);
  /*position: fixed;*/
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // justify-content: space-between;
  box-shadow: 5px 0 15px 0 #eeeeee;
  position: relative;
  &__container {
    // width: calc(1 / 6 * 100vw);
    // padding: calc(1 / 6 * 15vw) calc(1 / 6 * 12vw) calc(1 / 6 * 12vw) calc(1 / 6 * 12vw);
    // position: fixed;
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
}
</style>
