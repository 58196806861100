<template>
  <div class="notify_bot" style="position: relative" v-if="value && !value.chat_id">
    <img
      src="@/assets/img/close.png"
      style="width: 20px; position: absolute; right: -15px; top: 10px"
      alt=""
      class="cursor-pointer"
      @click="$emit('close')"
      v-if="closable"
    />
    <div class="d-flex">
      <div class="icon_tg">
        <img src="@/assets/img/tg.png" alt="" />
      </div>
      <div class="content_tg">
        <p style="margin-bottom: 32px">
          {{ $t("subscribe_content") }}
        </p>
        <b-btn class="customSuccess modal-btn" @click="to_tg" :disabled="pass_success">{{
          $t("subscribe")
        }}</b-btn>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import { mapGetters } from "vuex";
export default {
  props: ["closable"],
  data() {
    return {
      value: null,
    };
  },
  methods: {
    async init() {
      var response = await api.get(`bot/get?group=${this.simInfo.group}`);
      this.value = response.data;
    },
    to_tg() {
      window.open(`${this.value.bot_link}?start=${this.value.code}`, "_blank");
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      simInfo: "simulator/getSimInfo",
    }),
  },
};
</script>
<style lang="scss" scoped>
.notify_bot {
  background-color: #f1f1f1;
  padding: 24px 31px;
  img {
    width: 30px;
    margin-right: 32px;
  }
}
</style>
