import api from '@/api/'
import router from '../../router'
import i18n from '../../i18n';

export default {
    namespaced: true,
    state() {
      return {
        meInfo: undefined,
        token: localStorage.getItem("token"),
        isLogin: localStorage.getItem("token") ? true : false,
        onboarding: null,
        onboarding_complete: true,
        page: {
          notifications: null,
          content: null,
          theorys: null,
        },
      }
    },
    getters: {
      getMeInfo: stateFreezed => stateFreezed.meInfo,
      getToken: stateFreezed => stateFreezed.token,
      getIsLogin: stateFreezed => stateFreezed.isLogin,
      getOnboardingComplete: stateFreezed => stateFreezed.onboarding_complete,
      getUserCreated: (state) => {
        if (state.meInfo.first_name && state.meInfo.first_name != ''
            && state.meInfo.last_name && state.meInfo.last_name != ''
            && state.meInfo.avatar
            && state.meInfo.email && state.meInfo.email != '')
          return true
        else
          return false
      }
    },
    actions: {
      async FETCH ({commit}, routerNext = undefined) {
        await api.get('users/details.json').then(response => {
          if(response.data.curr_simulator == 0)
          {
            if(response.data.sim_info.simulator != 80)
            {
              api.defaults.headers['X-App-Name'] = 'base.tommy.com.ua';

              api.get('users/details.json').then(response2 => {
                commit('SET_USER', response2.data)
              })
              
            }
            commit('SET_USER', response.data)
          }
          else
          {
            if(response.data.sim_info.simulator == response.data.curr_simulator)
            {
              commit('SET_USER', response.data)
            }
            else
            {
              api.get(`simulators/${response.data.curr_simulator}/get_domain.json`).then(response2 => {
                api.defaults.headers['X-App-Name'] = response2.data.domain;
                api.get('users/details.json').then(response3 => {
                  commit('SET_USER', response3.data)
                })
              })
              .catch(() => {
                api.defaults.headers['X-App-Name'] = 'tommy_ux.2.mysimulator.ru';
                api.patch(`users/edit.json/`, { curr_simulator: 0 })
                .then(() => {
                  api.get('users/details.json').then(response3 => {
                    commit('SET_USER', response3.data)
                  })
                })
                
              })
            }
          }

          
          // commit('SET_ONBOARDING_COMPLETE', response.data.sim_info.onboarding_complete)
        }).catch(error => {
          console.log(error.message)
          commit('SET_USER', undefined)
          router.push({ name: 'Login' })
        }).finally(() => {
          if (routerNext)
            routerNext()
        })
      },
      async FETCH_BALANCE ({ commit }) {
        await api.get('/users/balance.json').then(response => {
          commit('SET_BALANCE', response.data.balance)
        }).catch(error => {
          console.log(error)
        })
      },
      async LOGIN (context, user) {
        const data = new FormData();
        data.append('username',user.username)
        data.append('password',user.password)
        await api.post("auth/", data)
        .then(async response => {
          context.commit('SET_TOKEN', response.data)
          await context.dispatch('FETCH')
          router.push({ name: 'Main' })
        }).catch(error => {
          if (error.response.status === 401 ||error.response.status === 400) {
            user.vm.$bvToast.toast(i18n.t('login_error_text'), {
              title: i18n.t('login_error_title'),
              variant: 'danger',
              solid: true
            })
          }
        })
      },
      async TEMPLOGIN (context, user){
        const data = new FormData()
        data.append('code', user.code)
        
        api.post("auth/v2/token/", data)
        .then(async response => {
          context.commit('SET_TOKEN', response.data);
          api.get('users/details.json').then(response2 => {
            
            api.put("simulators/set_started.json", { simulator:response2.data.sim_info.simulator})
            .then(() => {
              context.commit('SET_USER', response2.data)
              router.push({ name: 'Main' })
            })
          })
          
          
        })

      },
      async SOCIAL_AUTH (context, [vm, provider]) {
        const socialWindow = window.open("about:blank", "", "height=600,width=600");
        socialWindow.document.write("Redirect...");

        const response = await api.get("auth/v2/oauth/init/", {
          params: {
            provider: provider
          }
        }).catch(error => {
          console.log('error', error.message);
        });

        if (response && response.status === 200) {
          socialWindow.location.replace(response.data.url)
          socialWindow.focus()

          let timer = setInterval(() => {
            api.get(`/auth/v2/${response.data.attemptID}.json`)
            .then(result => {
              if (socialWindow.closed || result.data.status > 0) {
                socialWindow.close()
                clearInterval(timer)
              }

              if (result.data.status === 1) {
                vm.$bvToast.toast(i18n.t('social_error_app_text'), {
                  title: i18n.t('social_error_app_title'),
                  variant: 'danger',
                  solid: true
                })
              }

              if (result.data.status === 3) {
                router.push({ path: `/tempkey/${result.data.code}` })
              }
            }).catch(() => {
              socialWindow.close()
              clearInterval(timer)
            })
          }, 2000)
        }
      },
      async SIGN_UP (context, user) {
        const data = new FormData();
        data.append('username',user.username)
        data.append('email',user.email)
        data.append('password',user.password)
        data.append('re_password',user.password)
        data.append("group", user.group)
        data.append("utm", JSON.stringify(user.utm))

        await api.post("users.json", data)
        .then(response => {
          context.dispatch('LOGIN', {username: response.data.username, password: user.password})
        })
        .catch(error => {
          if (error.response.status === 500) {
            user.vm.$bvToast.toast(i18n.t('already_reg'), {
              title: i18n.t('already_reg_title'),
              variant: 'danger',
              solid: true
            })
          }
          if (error.response.status === 400 || error.response.status === 400) {
            user.vm.$bvToast.toast(i18n.t('already_reg'), {
              title: i18n.t('already_reg_title'),
              variant: 'danger',
              solid: true
            })
          }
        })
      },
      async RESET_PASSWORD(context, recover_pass){
        const data = new FormData()
        data.append("email", recover_pass.email)
        
        await api.post("/users/reset_password.json", data)
        .then(() => {
          recover_pass.vm.$bvToast.toast(i18n.t('check_email_text'), {
            title: i18n.t('success'),
            variant: "success",
            solid: true,
          })
        }).catch((error) => {
          if (error.response.status == 400) {
            recover_pass.vm.$bvToast.toast(i18n.t('no_email_text'), {
              title: i18n.t('error'),
              variant: 'danger',
              solid: true
            })
          }
          
        })
      },
      async CHANGE_PASSWORD(context, formdata){
        const data = new FormData();
        data.append("key", formdata.key);
        data.append("password", formdata.password);

        return await api.post("/users/change_password.json", data)
        .then(() => {
          formdata.vm.$bvToast.toast(i18n.t('password_changed'), {
            title: i18n.t('success'),
            variant: "success",
            solid: true,
          })

          return true
        }).catch(() => {
          formdata.vm.$bvToast.toast(' ', {
            title: i18n.t('error'),
            variant: 'danger',
            solid: true
          })

          return false
        })
      },
      async FORCE_CHANGE_PASSWORD(context, formdata){
        const data = new FormData();
        data.append("password", formdata.password);

        return await api.post("/users/force_change_password.json", data)
        .then(() => {
          formdata.vm.$bvToast.toast(i18n.t('password_changed'), {
            title: i18n.t('success'),
            variant: "success",
            solid: true,
          })

          return true
        }).catch(() => {
          formdata.vm.$bvToast.toast(' ', {
            title: i18n.t('error'),
            variant: 'danger',
            solid: true
          })

          return false
        })
      },
      async MAKE_USER({commit, dispatch}, authData){
        const data = new FormData();
        data.append('first_name', authData.first_name);
        data.append('last_name', authData.last_name);
        data.append('email', authData.email);

        if (authData.gender == 'Ж')
          data.append('male', false)
        else
          data.append('male', true)

        if (authData.icon)
          data.append('avatar', authData.icon)

        
          await api.put('users/edit.json', data)
          .then(response => {
            commit('SET_USER', response.data)

            if(authData.needtonotify && authData.needtonotify != '')
              dispatch('simulator/SEND_NOTIFICATION', {
                user_id: authData.id, 
                user_email: authData.email, 
                type: 'makeuser', 
                name:authData.name, 
                surname: authData.surname, 
                utm: authData.utm, 
                gender: authData.gender
              }, { root:true })
            if(authData.onboarding_skip)
            {
              router.push({ name: 'Main' })
            }
            else
            {
              router.push({ name: 'Main' })
            }
            
          }).catch(error => {
            if (error.response.status == 401) {
              router.push({ name: 'Login' })
            }
          })
        
      }, 
      async EDIT_USER({commit, getters}, authData) {
        const token = getters.getToken
        const data = new FormData();
        data.append('first_name', authData.first_name);
        // data.append('last_name', authData.last_name);
        // data.append('email', authData.email);
 
        // if (authData.gender == 'Ж')
        //   data.append('male', false)
        // else
        //   data.append('male', true)

        // if (authData.icon)
        //   data.append('avatar', authData.icon)

        if (token) {
          await api.put('users/edit.json', data)
          .then(response => {
            commit('SET_USER', response.data)
          }).catch(error => {
            if (error.response.status == 401) {
              router.push({ name: 'Login' })
            }
          })
        }
      }
    },
    mutations: {
      SET_USER (state, response) {
        if (!response) {
          localStorage.removeItem('token');
          state.token = null;
          state.isLogin = false;
          delete api.defaults.headers['Authorization'];
        }
        // console.log(response)
        state.meInfo = response
      },
      SET_CURRENT_PAGE(state, page){
        state.meInfo.sim_info.current_page = page;
      },
      CHANGE_BALANCE(state, value){
        state.meInfo.balance += value;
      },
      SET_BALANCE(state, value){
        state.meInfo.balance = value;
      },
      SET_TOKEN (state, response){
        state.token = response.token
        // console.log(response.token)
        state.isLogin = true
        api.defaults.headers['Authorization'] = `Token ${response.token}`;
        localStorage.setItem("token", response.token)
      },
      SET_LOGOUT(state) {
        localStorage.removeItem('token');
        state.isLogin = false
        state.token = null,
        state.user = {
          first_name: null,
          last_name: null,
          icon: null,
          gender: null,
          email: null,
          coin: 0
        },
        state.meInfo = undefined,
        state.page = null,
        state.onboarding = null
      },
      SET_ONBOARDING_COMPLETE(state, complete){
        state.meInfo.sim_info.onboarding_complete = complete
      }
    }
  }
