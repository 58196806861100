<template>
  <div>
    <Slide
      right
      width="1200"
      :isOpen="openProfile"
      :burgerIcon="false"
      :crossIcon="false"
      @closeMenu="handleCloseMenu"
    >
      <div class="content mt-1 px-md-5 px-2" v-if="edit_user">
        <b-container v-if="!to_edit_pass">
          <b-row class="mb-5">
            <b-col class="d-flex align-items-center">
              <div class="edit_title">
                {{ $t("profile_edit") }}
              </div>
              <div
                class="exit ml-auto d-flex align-items-center mr-5 cursor-pointer"
                @click.stop="logout"
              >
                <img
                  src="@/assets/img/exit.png"
                  alt=""
                  class="mr-2"
                  style="width: 16px"
                />{{ $t("logout") }}
              </div>
              <img
                src="@/assets/img/close.png"
                style="width: 40px"
                alt=""
                class="cursor-pointer"
                @click.stop="openProfile = false"
              />
            </b-col>
          </b-row>
          <Bot class="mb-2" />
          <b-row>
            <b-col md="6" class="field">
              <label for="">Ім'я</label>
              <b-input
                style="
                  border: none !important;
                  border-bottom: 1px black solid;
                  border-radius: 0 !important;
                "
                v-model="edit_user.first_name"
              ></b-input>
            </b-col>
            <!-- <b-col md="6" class="field">
              <label for="">Прізвище</label>
              <b-input
                style="
                  border: none !important;
                  border-bottom: 1px black solid;
                  border-radius: 0 !important;
                "
                :state="errorPromo"
                :placeholder="$t('enter_promocode')"
                v-model="promocode"
              ></b-input>
            </b-col> -->
          </b-row>
          <b-row>
            <b-col
              class="d-flex align-items-center change_password mt-4 cursor-pointer"
              @click.stop="to_edit_pass = true"
            >
              <img src="@/assets/img/pen.png" alt="" class="mr-2" style="width: 16px" />{{
                $t("change_password")
              }}
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col md="6">
              <b-btn class="customSuccess modal-btn w-100" @click="edit_user_action">{{
                $t("save")
              }}</b-btn>
            </b-col>
            <b-col md="6" class="mt-3 mt-md-0">
              <b-btn
                class="modal-btn customOutline w-100"
                @click.stop="openProfile = false"
                >{{ $t("cancel") }}</b-btn
              >
            </b-col>
          </b-row>
        </b-container>
        <b-container class="password_change" v-else>
          <b-row>
            <b-col class="d-flex align-items-center">
              <div class="edit_title">
                {{ $t("change_password_title") }}
              </div>
              <div
                class="exit ml-auto d-flex align-items-center mr-5 cursor-pointer"
                @click="logout"
              >
                <img
                  src="@/assets/img/exit.png"
                  alt=""
                  class="mr-2"
                  style="width: 16px"
                />{{ $t("logout") }}
              </div>
              <img
                src="@/assets/img/close.png"
                style="width: 40px"
                alt=""
                class="cursor-pointer"
                @click.stop="openProfile = false"
              />
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col md="6" class="field">
              <label for="">{{ $t("pass") }}</label>
              <b-input
                style="
                  border: none !important;
                  border-bottom: 1px black solid;
                  border-radius: 0 !important;
                "
                v-model="password"
                :type="!show_pass ? 'password' : 'text'"
              >
              </b-input>
              <img
                src="@/assets/img/eye-close.png"
                class="cursor-pointer"
                @click="show_pass = !show_pass"
                v-if="!show_pass"
                alt=""
                style="width: 19px; top: 46px; position: absolute; right: 20px"
              />
              <img
                src="@/assets/img/eye_open.png"
                class="cursor-pointer"
                @click="show_pass = !show_pass"
                v-else
                alt=""
                style="width: 19px; top: 46px; position: absolute; right: 20px"
              />
            </b-col>
            <b-col md="6" class="field">
              <label for="">{{ $t("pass2") }}</label>
              <b-input
                style="
                  border: none !important;
                  border-bottom: 1px black solid;
                  border-radius: 0 !important;
                "
                :state="errorPass"
                v-model="password2"
                :type="!show_pass ? 'password' : 'text'"
              ></b-input>
              <img
                src="@/assets/img/eye-close.png"
                class="cursor-pointer"
                @click="show_pass = !show_pass"
                v-if="!show_pass"
                alt=""
                style="width: 19px; top: 46px; position: absolute; right: 20px"
              />
              <img
                src="@/assets/img/eye_open.png"
                class="cursor-pointer"
                @click="show_pass = !show_pass"
                v-else
                alt=""
                style="width: 19px; top: 46px; position: absolute; right: 20px"
              />
              <b-form-invalid-feedback id="input-live-feedback2">
                {{ $t("password_not_match") }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col md="6">
              <b-btn
                class="customSuccess modal-btn w-100"
                @click="change_password"
                :disabled="pass_success"
                >{{ $t("save") }}</b-btn
              >
            </b-col>
            <b-col md="6" class="mt-3 mt-md-0">
              <b-btn
                class="modal-btn customOutline w-100"
                @click.stop="openProfile = false"
                >{{ $t("cancel") }}</b-btn
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </Slide>
    <Drawer
      @close="openMenu = false"
      align="left"
      :closeable="false"
      :maskClosable="true"
    >
      <Sidebar v-if="openMenu" @close="openMenu = false" />
    </Drawer>
    <nav class="bg-white" v-if="user">
      <b-container class="d-flex align align-items-center h-100">
        <div class="logo cursor-pointer">
          <img src="@/assets/img/logo.svg" alt="" @click="to('/home')" />
        </div>
        <div class="ml-md-5 ml-1 d-none d-lg-block">
          <a
            href="https://www.tommy.com.ua/"
            target="_blank"
            class="btn btn-outline-dark"
            style="border-radius: 80px; padding: 8px 24px"
            role="button"
            aria-pressed="true"
            >{{ $t("all_courses") }}</a
          >
        </div>
        <img
          src="@/assets/img/burger.png"
          class="burger d-block d-lg-none mr-auto"
          alt=""
          @click.stop="openMenu = true"
          style="margin-left: 15px"
        />
        <vue-ellipse-progress
          :progress="
            Math.min((user.sim_info.completed_places / simInfo.max_places) * 100, 100)
          "
          color="#00FA9A"
          emptyColor="#000"
          :size="40"
          :fontSize="'24'"
          style="font-weight: bold; margin-right: 10px"
          :thickness="4"
          :emptyThickness="4"
          :angle="90"
          class="ml-auto d-lg-none"
        >
          <img src="@/assets/img/hat2.png" style="width: 18px; margin: 0 auto" alt="" />
        </vue-ellipse-progress>
        <div
          style="
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            margin-right: 15px;
          "
          class="d-lg-none"
        >
          {{
            Math.min(
              ((user.sim_info.completed_places / simInfo.max_places) * 100).toFixed(0),
              100
            )
          }}%
        </div>
        <div
          class="profile d-flex ml-lg-auto align-items-center cursor-pointer"
          @click.stop="openProfile = true"
        >
          <div class="name d-none d-md-block mr-2">
            {{ user.first_name }}
          </div>
          <img :src="user.avatar.replace('http://', 'https://')" alt="" />
        </div>
      </b-container>
    </nav>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Slide } from "vue-burger-menu";
import Drawer from "vue-simple-drawer";
import Sidebar from "@/views/SideBar";
import Bot from "@/components/global/bot.vue";
export default {
  computed: {
    ...mapGetters({
      user: "user/getMeInfo",
      simInfo: "simulator/getSimInfo",
    }),
    pass_success() {
      return this.password && this.password.length > 0 ? false : true;
    },
  },
  components: {
    Slide,
    Drawer,
    Bot,
    Sidebar,
  },
  data() {
    return {
      openProfile: false,
      openMenu: false,
      edit_user: null,
      to_edit_pass: false,
      errorPass: null,
      password: null,
      password2: null,
      show_pass: false,
    };
  },
  mounted() {
    this.openProfile = true;
    this.openProfile = false;
    if (this.user) {
      this.edit_user = this.user;
    }
  },
  methods: {
    async logout() {
      await this.handleCloseMenu();
      this.to_edit_pass = false;
      this.password = null;
      this.password2 = null;
      this.errorPass = null;
      this.show_pass = false;
      this.$store.commit("user/SET_LOGOUT");
      setTimeout("", 1000);
      this.$router.push({ name: "Login" });
    },
    async change_password() {
      if (this.password != this.password2) {
        this.errorPass = false;
      } else {
        this.$store.dispatch("user/FORCE_CHANGE_PASSWORD", {
          vm: this,
          password: this.password,
        });
        this.openProfile = false;
        this.to_edit_pass = false;
        this.password = null;
        this.password2 = null;
        this.errorPass = null;
        this.show_pass = false;
      }
    },
    async edit_user_action() {
      await this.$store.dispatch("user/EDIT_USER", {
        first_name: this.edit_user.first_name,
      });
      this.openProfile = false;
    },
    async handleCloseMenu() {
      this.openProfile = false;
      this.to_edit_pass = false;
      this.password = null;
      this.password2 = null;
      this.errorPass = null;
      this.show_pass = false;
    },

    to(to) {
      this.$router.push(to);
    },
  },
  watch: {
    agree(val) {
      if (val) {
        this.false_click = false;
      }
    },
    user() {
      this.edit_user = this.user;
    },
    password2() {
      if (this.password2 == this.password) {
        this.errorPass = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
nav {
  height: 72px;
  .customBTN {
    padding: 8px 24px !important;
    font-size: 18px;
    line-height: 1;
  }
  .customBTN:hover {
    color: white !important;
  }
  .profile {
    img {
      border-radius: 20px;
      height: 40px;
    }
  }
}
@media (max-width: 767.98px) {
  nav .align {
    justify-content: space-between !important;
  }
}
</style>
<style lang="scss">
.burger {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .modal-btn {
    width: 100% !important;
  }
  .main main .teachers {
    margin-top: 12px !important;
  }
  .main main .tags {
    margin-top: 24px !important;
  }
}
.text-danger .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
  border-color: red !important;
}
body {
  &.bm-overlay {
    .bm-menu {
      width: 800px !important;
      .content {
        width: 800px !important;
      }
    }
  }
}
.exit {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
}
.edit_title {
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
}
.field {
  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
  }
}
.change_password {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.password_change .was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}
@media (max-width: 991.98px) {
  body {
    &.bm-overlay {
      .bm-menu {
        width: 100% !important;
        .content {
          width: 100% !important;
        }
      }
    }
  }
}
.vue-simple-drawer {
  padding: 0 !important;
  color: black !important;
}
</style>
